let utils = {
  //获取当前时间戳
  getTimestamp() {
    return new Date().getTime()
  },
  //获取缓存
  getStorage(name) {
    return sessionStorage.getItem(name);
  },
  //全屏
  fullScreen() {
    var element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  },
  //退出全屏
  exitFullscreen(){
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    
  },
  //数组随机排序
  shuffle(arr) {
    arr.sort(function () {
      return Math.random() - 0.5;
    });
    return arr;
  },
  //获取区间随机整数
  getRandomInt(min,max){
    return Math.floor(Math.random()*(max-min+1))+min;
  },
  //从数组中随机取几个
  getRandomArrEle(arr, count) {
    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
  },
  //判断pc端和移动端
  isPC() {  
    var userAgentInfo = navigator.userAgent;
    var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
    var flag = true;  
    for (var v = 0; v < Agents.length; v++) {  
        if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
    }  
    return flag;  
 },
}
export default utils
