// let cardData = [
//   {
//     id: 1,
//     name: 'circle1blue',
//     color: 'blue',
//     shape: 'circle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/circle1blue.png'),
//   },
//   {
//     id: 2,
//     name: 'circle1green',
//     color: 'green',
//     shape: 'circle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/circle1green.png'),
//   },
//   {
//     id: 3,
//     name: 'circle1red',
//     color: 'red',
//     shape: 'circle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/circle1red.png'),
//   },
//   {
//     id: 4,
//     name: 'circle1yellow',
//     color: 'yellow',
//     shape: 'circle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/circle1yellow.png'),
//   },
//   {
//     id: 5,
//     name: 'circle2blue',
//     color: 'blue',
//     shape: 'circle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/circle2blue.png'),
//   },
//   {
//     id: 6,
//     name: 'circle2green',
//     color: 'green',
//     shape: 'circle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/circle2green.png'),
//   },
//   {
//     id: 7,
//     name: 'circle2red',
//     color: 'red',
//     shape: 'circle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/circle2red.png'),
//   },
//   {
//     id: 8,
//     name: 'circle2yellow',
//     color: 'yellow',
//     shape: 'circle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/circle2yellow.png'),
//   },
//   {
//     id: 9,
//     name: 'circle3blue',
//     color: 'blue',
//     shape: 'circle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/circle3blue.png'),
//   },
//   {
//     id: 10,
//     name: 'circle3green',
//     color: 'green',
//     shape: 'circle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/circle3green.png'),
//   },
//   {
//     id: 11,
//     name: 'circle3red',
//     color: 'red',
//     shape: 'circle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/circle3red.png'),
//   },
//   {
//     id: 12,
//     name: 'circle3yellow',
//     color: 'yellow',
//     shape: 'circle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/circle3yellow.png'),
//   },
//   {
//     id: 13,
//     name: 'circle4blue',
//     color: 'blue',
//     shape: 'circle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/circle4blue.png'),
//   },
//   {
//     id: 14,
//     name: 'circle4green',
//     color: 'green',
//     shape: 'circle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/circle4green.png'),
//   },
//   {
//     id: 15,
//     name: 'circle4red',
//     color: 'red',
//     shape: 'circle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/circle4red.png'),
//   },
//   {
//     id: 16,
//     name: 'circle4yellow',
//     color: 'yellow',
//     shape: 'circle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/circle4yellow.png'),
//   },
//   {
//     id: 17,
//     name: 'cross1blue',
//     color: 'blue',
//     shape: 'cross',
//     num: '1',
//     src: require('@/assets/image/card/reaction/cross1blue.png'),
//   },
//   {
//     id: 18,
//     name: 'cross1green',
//     color: 'green',
//     shape: 'cross',
//     num: '1',
//     src: require('@/assets/image/card/reaction/cross1green.png'),
//   },
//   {
//     id: 19,
//     name: 'cross1red',
//     color: 'red',
//     shape: 'cross',
//     num: '1',
//     src: require('@/assets/image/card/reaction/cross1red.png'),
//   },
//   {
//     id: 20,
//     name: 'circle1blue',
//     color: 'yellow',
//     shape: 'cross',
//     num: '1',
//     src: require('@/assets/image/card/reaction/cross1yellow.png'),
//   },
//   {
//     id: 21,
//     name: 'cross2blue',
//     color: 'blue',
//     shape: 'cross',
//     num: '2',
//     src: require('@/assets/image/card/reaction/cross2blue.png'),
//   },
//   {
//     id: 22,
//     name: 'cross2green',
//     color: 'green',
//     shape: 'cross',
//     num: '2',
//     src: require('@/assets/image/card/reaction/cross2green.png'),
//   },
//   {
//     id: 23,
//     name: 'cross2red',
//     color: 'red',
//     shape: 'cross',
//     num: '2',
//     src: require('@/assets/image/card/reaction/cross2red.png'),
//   },
//   {
//     id: 24,
//     name: 'cross2yellow',
//     color: 'yellow',
//     shape: 'cross',
//     num: '2',
//     src: require('@/assets/image/card/reaction/cross2yellow.png'),
//   },
//   {
//     id: 25,
//     name: 'cross3blue',
//     color: 'blue',
//     shape: 'cross',
//     num: '3',
//     src: require('@/assets/image/card/reaction/cross3blue.png'),
//   },
//   {
//     id: 26,
//     name: 'cross3green',
//     color: 'green',
//     shape: 'cross',
//     num: '3',
//     src: require('@/assets/image/card/reaction/cross3green.png'),
//   },
//   {
//     id: 27,
//     name: 'cross3red',
//     color: 'red',
//     shape: 'cross',
//     num: '3',
//     src: require('@/assets/image/card/reaction/cross3red.png'),
//   },
//   {
//     id: 28,
//     name: 'cross3yellow',
//     color: 'yellow',
//     shape: 'cross',
//     num: '3',
//     src: require('@/assets/image/card/reaction/cross3yellow.png'),
//   },
//   {
//     id: 29,
//     name: 'cross4blue',
//     color: 'blue',
//     shape: 'cross',
//     num: '4',
//     src: require('@/assets/image/card/reaction/cross4blue.png'),
//   },
//   {
//     id: 30,
//     name: 'cross4green',
//     color: 'green',
//     shape: 'cross',
//     num: '4',
//     src: require('@/assets/image/card/reaction/cross4green.png'),
//   },
//   {
//     id: 31,
//     name: 'cross4red',
//     color: 'red',
//     shape: 'cross',
//     num: '4',
//     src: require('@/assets/image/card/reaction/cross4red.png'),
//   },
//   {
//     id: 32,
//     name: 'cross4yellow',
//     color: 'yellow',
//     shape: 'cross',
//     num: '4',
//     src: require('@/assets/image/card/reaction/cross4yellow.png'),
//   },
//   {
//     id: 33,
//     name: 'star1blue',
//     color: 'blue',
//     shape: 'star',
//     num: '1',
//     src: require('@/assets/image/card/reaction/star1blue.png'),
//   },
//   {
//     id: 34,
//     name: 'star1green',
//     color: 'green',
//     shape: 'star',
//     num: '1',
//     src: require('@/assets/image/card/reaction/star1green.png'),
//   },
//   {
//     id: 35,
//     name: 'star1red',
//     color: 'red',
//     shape: 'star',
//     num: '1',
//     src: require('@/assets/image/card/reaction/star1red.png'),
//   },
//   {
//     id: 36,
//     name: 'star1yellow',
//     color: 'yellow',
//     shape: 'star',
//     num: '1',
//     src: require('@/assets/image/card/reaction/star1yellow.png'),
//   },
//   {
//     id: 37,
//     name: 'star2blue',
//     color: 'blue',
//     shape: 'star',
//     num: '2',
//     src: require('@/assets/image/card/reaction/star2blue.png'),
//   },
//   {
//     id: 38,
//     name: 'star2green',
//     color: 'green',
//     shape: 'star',
//     num: '2',
//     src: require('@/assets/image/card/reaction/star2green.png'),
//   },
//   {
//     id: 39,
//     name: 'star2red',
//     color: 'red',
//     shape: 'star',
//     num: '2',
//     src: require('@/assets/image/card/reaction/star2red.png'),
//   },
//   {
//     id: 40,
//     name: 'star2yellow',
//     color: 'yellow',
//     shape: 'star',
//     num: '2',
//     src: require('@/assets/image/card/reaction/star2yellow.png'),
//   },
//   {
//     id: 41,
//     name: 'star3blue',
//     color: 'blue',
//     shape: 'star',
//     num: '3',
//     src: require('@/assets/image/card/reaction/star3blue.png'),
//   },
//   {
//     id: 42,
//     name: 'star3green',
//     color: 'green',
//     shape: 'star',
//     num: '3',
//     src: require('@/assets/image/card/reaction/star3green.png'),
//   },
//   {
//     id: 43,
//     name: 'star3red',
//     color: 'red',
//     shape: 'star',
//     num: '3',
//     src: require('@/assets/image/card/reaction/star3red.png'),
//   },
//   {
//     id: 44,
//     name: 'star3yellow',
//     color: 'yellow',
//     shape: 'star',
//     num: '3',
//     src: require('@/assets/image/card/reaction/star3yellow.png'),
//   },
//   {
//     id: 45,
//     name: 'star4blue',
//     color: 'blue',
//     shape: 'star',
//     num: '4',
//     src: require('@/assets/image/card/reaction/star4blue.png'),
//   },
//   {
//     id: 46,
//     name: 'star4green',
//     color: 'green',
//     shape: 'star',
//     num: '4',
//     src: require('@/assets/image/card/reaction/star4green.png'),
//   },
//   {
//     id: 47,
//     name: 'star4red',
//     color: 'red',
//     shape: 'star',
//     num: '4',
//     src: require('@/assets/image/card/reaction/star4red.png'),
//   },
//   {
//     id: 48,
//     name: 'star4yellow',
//     color: 'yellow',
//     shape: 'star',
//     num: '4',
//     src: require('@/assets/image/card/reaction/star4yellow.png'),
//   },
//   {
//     id: 49,
//     name: 'triangle1blue',
//     color: 'blue',
//     shape: 'triangle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/triangle1blue.png'),
//   },
//   {
//     id: 50,
//     name: 'triangle1green',
//     color: 'green',
//     shape: 'triangle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/triangle1green.png'),
//   },
//   {
//     id: 51,
//     name: 'triangle1red',
//     color: 'red',
//     shape: 'triangle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/triangle1red.png'),
//   },
//   {
//     id: 52,
//     name: 'triangle1yellow',
//     color: 'yellow',
//     shape: 'triangle',
//     num: '1',
//     src: require('@/assets/image/card/reaction/triangle1yellow.png'),
//   },
//   {
//     id: 53,
//     name: 'triangle2blue',
//     color: 'blue',
//     shape: 'triangle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/triangle2blue.png'),
//   },
//   {
//     id: 54,
//     name: 'triangle2green',
//     color: 'green',
//     shape: 'triangle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/triangle2green.png'),
//   },
//   {
//     id: 55,
//     name: 'triangle2red',
//     color: 'red',
//     shape: 'triangle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/triangle2red.png'),
//   },
//   {
//     id: 56,
//     name: 'triangle2yellow',
//     color: 'yellow',
//     shape: 'triangle',
//     num: '2',
//     src: require('@/assets/image/card/reaction/triangle2yellow.png'),
//   },
//   {
//     id: 57,
//     name: 'triangle3blue',
//     color: 'blue',
//     shape: 'triangle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/triangle3blue.png'),
//   },
//   {
//     id: 58,
//     name: 'triangle3green',
//     color: 'green',
//     shape: 'triangle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/triangle3green.png'),
//   },
//   {
//     id: 59,
//     name: 'triangle3red',
//     color: 'red',
//     shape: 'triangle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/triangle3red.png'),
//   },
//   {
//     id: 60,
//     name: 'triangle3yellow',
//     color: 'yellow',
//     shape: 'triangle',
//     num: '3',
//     src: require('@/assets/image/card/reaction/triangle3yellow.png'),
//   },
//   {
//     id: 61,
//     name: 'triangle4blue',
//     color: 'blue',
//     shape: 'triangle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/triangle4blue.png'),
//   },
//   {
//     id: 62,
//     name: 'triangle4green',
//     color: 'green',
//     shape: 'triangle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/triangle4green.png'),
//   },
//   {
//     id: 63,
//     name: 'triangle4red',
//     color: 'red',
//     shape: 'triangle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/triangle4red.png'),
//   },
//   {
//     id: 64,
//     name: 'triangle4yellow',
//     color: 'yellow',
//     shape: 'triangle',
//     num: '4',
//     src: require('@/assets/image/card/reaction/triangle4yellow.png'),
//   },
// ];
const state = {
  // activeIndex: 1,
  // card:cardData
};

const getters = {
  // getCard:(state) => {
  //   return state.card
  //   // console.log(state.card)
  // },
  // allTodos: (state) => state.todos,
  // doneTodos: (state) => state.todos.filter((todo) => todo.done), //done:true状态
  // doneTodosCount: (state, getters) => {
  //   //done:true状态长度
  //   return getters.doneTodos.length;
  // },
  // getTodoById: (state) => (id) => {
  //   //根据id获取
  //   return state.todos.find((todo) => todo.id === id);
  // },
};

const mutations = {
  // randomCard:(state, data) => {
  //   state.card = data;
  // },
  // addCount: (state, payload) => {
  //   state.count += payload.amount;
  // },
  // setTodos: (state, todos) => (state.todos = todos),
  // newTodo: (state, todo) => state.todos.unshift(todo),
  // removeTodo: (state, id) =>
  //   (state.todos = state.todos.filter((todo) => todo.id !== id)),
  // updateTodo: (state, updTodo) => {
  //   const index = state.todos.findIndex((todo) => todo.id === updTodo.id);
  //   if (index !== -1) {
  //     state.todos.splice(index, 1, updTodo);
  //   }
  // },
  // getTabActIndex(state, status) {
  //   state.activeIndex = status;
  // },
};

export default {
  state,
  getters,
  mutations,
};
