import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/login', 
    name: 'login',
    component: () => import('../views/login.vue') //登录
  },
  {
    path: '/evaluation', 
    name: 'evaluation',
    component: () => import('../views/evaluation.vue') //pc端测评
  },
  {
    path: '/mEvaluation', 
    name: 'mEvaluation',
    component: () => import('../views/mEvaluation.vue') //移动端测评
  },
  {
    path: '/finish', 
    name: 'finish',
    component: () => import('../views/finish.vue') //pc端测评完成
  },
  {
    path: '/mFinish', 
    name: 'mFinish',
    component: () => import('../views/mFinish.vue') //移动端测评完成
  },
  {
    path: '/ques', 
    name: 'ques',
    component: () => import('../views/dispense/ques.vue') //题库测评
  },
  {
    path: '/mQues', 
    name: 'mQues',
    component: () => import('../views/dispense/mQues.vue') //题库测评
  },
  {
    path: '/quesFinish', 
    name: 'quesFinish',
    component: () => import('../views/dispense/finish.vue') //题库测评完成
  },
  {
    path: '/mQuesFinish', 
    name: 'mQuesFinish',
    component: () => import('../views/dispense/mFinish.vue') //移动端题库测评完成
  },
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // 去的不是登录页
  if (to.path != '/login') {
      // 没有登录
      if (sessionStorage.getItem('fmTeaUserId')) {
        next();
      } else {
        next({
          path: '/login',
        });
      }
  } else {
    next();
  }
});
export default router
  